<template>
  <div>
    <Dialog :common-dialog="scheduleDialog" :dialog-width="dialogWidth">
      <template v-slot:title
        >Visit Schedule ({{ visitSchedule.length }}) {{ getDuration() }}
      </template>
      <template v-slot:body>
        <v-container style="min-height: 25vh">
          <table width="100%">
            <tr
              v-for="(row, index) in visitSchedule"
              :key="index"
              :class="{ 'visit-row-hover': row.is_edited }"
            >
              <td
                class="font-size-14 font-weight-500"
                align="center"
                valign="middle"
              >
                {{ index + 1 }}.
              </td>
              <td>
                <DatePicker
                  :disabled="!row.status"
                  placeholder="Date"
                  v-model="row.visit_date"
                  v-on:change="row.is_edited = 1"
                />
              </td>
              <td>
                <v-text-field
                  readonly
                  solo
                  flat
                  dense
                  :disabled="!row.status"
                  filled
                  hide-details
                  :value="getDayName(row.visit_date)"
                ></v-text-field>
              </td>
              <td>
                <TimePicker
                  key="visit-schedule-start-time"
                  id="start-time"
                  placeholder="Start Time"
                  v-model.trim="row.start_time"
                  :min-time="todayTime(row)"
                  v-on:change="(row.is_edited = 1), addTime(row, index)"
                />
                <!-- <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="start-time"
                  :items="timeList"
                  :disabled="!row.status"
                  v-model.trim="row.start_time"
                  v-on:change="row.is_edited = 1"
                  placeholder="Start Time"
                  label="Start Time"
                  solo
                  flat
                  item-color="cyan"
                  hide-details
                /> -->
              </td>
              <td>
                <TimePicker
                  key="visit-schedule-end-time"
                  id="end-time"
                  placeholder="End Time"
                  :min-time="updatedDate(row)"
                  v-model.trim="row.end_time"
                  v-on:change="row.is_edited = 1"
                />
                <!--   <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="end-time"
                  :items="timeList"
                  :disabled="!row.status"
                  v-model.trim="row.end_time"
                  v-on:change="row.is_edited = 1"
                  placeholder="End Time"
                  label="End Time"
                  solo
                  flat
                  item-color="cyan"
                  hide-details
                /> -->
              </td>
              <td align="center" valign="middle">
                <v-switch
                  class="m-0 p-0"
                  color="cyan"
                  dense
                  hide-details
                  :false-value="0"
                  :true-value="1"
                  v-on:change="row.is_edited = 1"
                  v-model="row.status"
                  inset
                ></v-switch>
              </td>
            </tr>
          </table>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="validateSchedule()"
        >
          Save
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="$emit('close', true)"
        >
          Close
        </v-btn>
      </template>
    </Dialog>

    <Dialog :common-dialog="dateValidateDialog" :dialog-width="dialogWidth">
      <template v-slot:title
        >Visit Schedule ({{ visitSchedule.length }}) {{ getDuration() }}
      </template>
      <template v-slot:body>
        <v-container>
          <v-layout>
            <v-flex md6 class="custom-border-right mr-2">
              <h3 class="color-custom-blue font-weight-600 font-size-19">
                Schedule Suggested by The System
              </h3>
              <table width="100%">
                <tr
                  v-for="(row, index) in rawVisitSchedule"
                  :key="index"
                  :class="{ 'visit-row-hover': row.is_edited }"
                >
                  <td
                    class="font-size-14 font-weight-500"
                    align="center"
                    valign="middle"
                  >
                    {{ index + 1 }}.
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ formatDate(row.visit_date) }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ getDayName(row.visit_date) }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ row.start_time || "-" }}
                  </td>
                  <td class="font-size-14 font-weight-500">
                    {{ row.end_time || "-" }}
                  </td>
                  <td>
                    <v-icon color="green lighten-1" v-if="row.status"
                      >mdi-check</v-icon
                    >
                    <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
                  </td>
                </tr>
              </table>
            </v-flex>
            <v-flex md6 class="ml-2">
              <h3 class="color-custom-blue font-weight-600 font-size-19">
                Changes Done by You
              </h3>
              <table width="100%">
                <template v-if="changedVisitSchedule.length">
                  <tr
                    v-for="(row, index) in changedVisitSchedule"
                    :key="index"
                    :class="{ 'visit-row-hover': row.is_edited }"
                  >
                    <td
                      class="font-size-14 font-weight-500"
                      align="center"
                      valign="middle"
                    >
                      {{ index + 1 }}.
                    </td>
                    <td class="font-size-14 font-weight-500">
                      {{ formatDate(row.visit_date) }}
                    </td>
                    <td class="font-size-14 font-weight-500">
                      {{ getDayName(row.visit_date) }}
                    </td>
                    <td class="font-size-14 font-weight-500">
                      <template v-if="row.start_time">
                        {{
                          row.start_time && row.start_time.hh
                            ? `${row.start_time.hh}:${row.start_time.mm} ${row.start_time.A}`
                            : row.start_time
                        }}
                      </template>
                      <template v-else> - </template>
                    </td>
                    <td class="font-size-14 font-weight-500">
                      <template v-if="row.end_time">
                        {{
                          row.end_time && row.end_time.hh
                            ? `${row.end_time.hh}:${row.end_time.mm} ${row.end_time.A}`
                            : row.end_time
                        }}
                      </template>
                      <template v-else> - </template>
                    </td>
                    <td>
                      <v-icon color="green lighten-1" v-if="row.status"
                        >mdi-check</v-icon
                      >
                      <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="6">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There is no changes done by you at the moment.
                    </p>
                  </td>
                </tr>
              </table>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="updateSchedule()"
        >
          Save
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="
            dateValidateDialog = false;
            $emit('open', true);
          "
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import moment from "moment-timezone";
import TimePicker from "@/view/components/TimePicker.vue";

import CommonMixin from "@/core/plugins/common-mixin";
import DatePicker from "@/view/components/QDatePicker.vue";
import { SET_VISIT_DATES } from "@/core/services/store/visit.module";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "recurring-schedule-dialog",
  mixins: [CommonMixin],
  props: {
    scheduleDialog: {
      type: Boolean,
      default: false,
    },
    responseSchedule: {
      type: Object,
      default() {
        return {};
      },
    },
    visitSchedule: {
      type: Array,
      default() {
        return [];
      },
    },
    rawVisitSchedule: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dateValidateDialog: false,
      changedVisitSchedule: [],
    };
  },
  components: {
    Dialog,
    DatePicker,
    TimePicker,
  },
  methods: {
    getDayName(date) {
      return moment(date).format("dddd");
    },
    addTime(row, index) {
      this.visitSchedule[index].end_time = moment(row.start_time, "hh:mm A")
        .add(30, "minutes")
        .format("hh:mm A");
    },
    getDuration() {
      let timing = false;
      let start_date_time = this.responseSchedule?.start_date;
      if (this.responseSchedule?.start_time) {
        timing = true;
        start_date_time =
          start_date_time + " " + this.responseSchedule?.start_time;
      }

      let end_date_time = this.responseSchedule?.end_date;
      if (this.responseSchedule?.end_time) {
        timing = true;
        end_date_time = end_date_time + " " + this.responseSchedule?.end_time;
      }

      let result = [];

      if (timing) {
        result.push(this.formatDateTime(start_date_time));
        result.push(this.formatDateTime(end_date_time));
      } else {
        result.push(this.formatDate(start_date_time));
        result.push(this.formatDate(end_date_time));
      }

      return result.join(" - ");
    },
    updateSchedule() {
      this.$emit("close", true);
      this.dateValidateDialog = false;
    },
    todayTime(row) {
      return moment(row.visit_date).format("hh:mm A");
    },
    updatedDate(row) {
      return moment(row.start_time, "hh:mm A")
        .add(30, "minutes")
        .format("hh:mm A");
    },
    validateSchedule() {
      this.changedVisitSchedule = this.lodash.differenceWith(
        this.visitSchedule,
        this.rawVisitSchedule,
        this.lodash.isEqual
      );

      this.$store.dispatch(SET_VISIT_DATES, this.visitSchedule);

      this.$nextTick(() => {
        this.$emit("close", true);
        this.dateValidateDialog = true;
      });
    },
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
