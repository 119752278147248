<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout class="px-4">
        <v-flex style="width: 90%">
          <p class="m-0 text-truncate">Select a {{ label }}</p>
        </v-flex>
        <v-flex class="text-right d-flex align-center">
          <v-btn
            :disabled="loading"
            style="height: 32px !important"
            class="custom-bold-button mr-2"
            depressed
            color="cyan white--text"
            v-on:click="selectAddress()"
            >Save</v-btn
          >
          <v-btn
            :disabled="loading"
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            v-on:click="$emit('close')"
            >Close</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div class="px-4 w-100">
        <label for="address-search" class="font-weight-600"
          >Which <span class="text-lowercase">{{ label }}</span> would you like
          to select this for?</label
        >
        <div class="d-flex mb-2">
          <v-text-field
            id="address-search"
            v-model="search"
            class="line-item-header-text px-0 v-slot-m-none"
            :placeholder="`Search ${label}...`"
            dense
            filled
            solo
            flat
            color="cyan"
            :loading="loading"
            :disabled="loading"
            style="max-width: 400px"
            clearable
          />
          <v-btn
            v-on:click="getAddress()"
            :loading="loading"
            :disabled="loading"
            style="height: 32px !important"
            class="custom-bold-button ml-2"
            depressed
            color="cyan white--text"
            >Search</v-btn
          >
        </div>
        <div
          class="custom-border-top"
          style="max-height: calc(100vh - 300px); overflow-y: scroll"
        >
          <div v-if="list && list.length">
            <v-treeview
              :active.sync="tree"
              :items="list"
              activatable
              shaped
              hoverable
              color="cyan"
              item-key="name"
              return-object
              :search="search"
              :filter="filter"
            >
              <template v-slot:append="{ item, open }">
                <div class="d-flex">
                  <div class="mr-2" v-if="item.type != 'child-child-'">
                    <v-icon color="cyan white--text">mdi-home</v-icon>
                    <span class="line-item-property-count">{{
                      item.child_count
                    }}</span>
                  </div>
                  <div class="ml-2">
                    <v-icon color="cyan white--text">mdi-hammer-wrench</v-icon>
                    <span class="line-item-property-count">{{
                      item.equipment_count
                    }}</span>
                  </div>
                </div>
              </template>
            </v-treeview>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center py-3 font-weight-500"
          >
            <img
              width="40px"
              :src="$assetURL('media/error/empty.png')"
              class="row-not-found-image mr-2"
            />
            Uhh... There are no
            <span class="text-lowercase"> {{ label }} </span> at the moment.
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
// import Barcode from "@/view/pages/partials/Barcode.vue";
import { QUERY } from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  name: "select-line-item-address",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      caseSensitive: false,
      search: null,
      tree: [],
      list: [],
    };
  },
  components: {
    Dialog,
    // Barcode,
  },
  methods: {
    selectAddress() {
      this.$emit("update", ObjectPath.get(this.tree, "0"));
      this.$emit("close", true);
      this.loading = false;
      this.search = null;
      this.list = [];
    },
    getAddress() {
      this.loading = true;
      this.list = [];
      this.$store
        .dispatch(QUERY, {
          url: "line-item-property",
          data: {
            customer: this.vCustomerId,
            property: this.vPropertyId,
            search: this.search,
          },
        })
        .then((response) => {
          this.list = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getAddress();
  },
  computed: {
    ...mapGetters(["vCustomer", "vCustomerId", "vPropertyId"]),
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
};
</script>
