<template>
  <Dialog :common-dialog="productDialog" :dialog-width="dialog_width">
    <template v-slot:title>
      <v-layout>
        <v-flex>
          <span class="text-capitalize">Select {{ productType }}s</span>
          <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon>
          Line Item
        </v-flex>
        <v-flex class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button mr-2"
            depressed
            :disabled="vStateLoading"
            color="cyan white--text"
            v-on:click="save_line_item()"
            >Save</v-btn
          >
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            :disabled="vStateLoading"
            v-on:click="$emit('close', true)"
            >Close</v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div>
        <div
          v-if="vLineItemAlpha && vLineItemAlpha.length"
          class="d-flex mb-2"
          style="overflow-x: scroll"
        >
          <div
            style="position: relative"
            v-for="(alpha, index) in vLineItemAlpha"
            :key="`alphabet-${index}`"
            :class="{ 'alpha-exist': alpha.exist }"
            class="alph-listing custom-border-right"
          >
            <v-btn
              color="cyan white--text custom-bold-button"
              depressed
              tile
              :disabled="vStateLoading"
              :text="alphabet == alpha.value"
              v-on:click="filter_alpha(alpha.value)"
            >
              {{ alpha.label }}
            </v-btn>
            <v-icon v-if="alpha.exist" color="red">mdi-record</v-icon>
          </div>
        </div>
        <div
          v-if="vLineItemCategory && vLineItemCategory.length"
          class="d-flex mb-2"
          style="overflow-x: scroll"
        >
          <div
            style="position: relative"
            v-for="(cat, index) in vLineItemCategory"
            :key="`category-${index}`"
            class="custom-border-right"
          >
            <v-btn
              color="cyan white--text custom-bold-button"
              depressed
              tile
              :disabled="vStateLoading"
              :text="category == cat.value"
              v-on:click="filter_cat(cat.value)"
            >
              {{ cat.label }}
            </v-btn>
          </div>
        </div>
        <div class="d-flex mb-2">
          <v-text-field
            v-model="search"
            class="line-item-header-text px-0 v-slot-m-none"
            placeholder="Search..."
            dense
            filled
            solo
            :disabled="vStateLoading"
            clearable
            flat
            color="cyan"
            style="max-width: 400px"
          />
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button ml-2"
            depressed
            :disabled="vStateLoading"
            v-on:click="find_items()"
            color="cyan white--text"
            >Search</v-btn
          >
        </div>
        <div>
          <div class="d-flex grey lighten-4">
            <div
              class="font-weight-600 py-2 text-center d-flex justify-center"
              style="width: 5%"
            >
              <v-checkbox
                v-on:change="update_all_line_item($event)"
                hide-details
                :disabled="vStateLoading"
                class="my-0 py-0 px-0 mx-0 dense-checkbox"
                color="cyan"
              />
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left text-capitalize"
              style="width: 60%"
            >
              {{ productType }}
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left"
              style="width: 17.5%"
            >
              Selling/Unit Cost
            </div>
            <div
              class="color-custom-blue py-2 font-weight-700 text-left"
              style="width: 17.5%"
            >
              Quantity
            </div>
          </div>
          <div style="overflow-y: scroll; max-height: calc(100vh - 360px)">
            <div v-if="vStateLoading" class="my-4 py-4">
              <v-progress-linear
                color="cyan"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
            </div>
            <div v-else-if="vLineItem && vLineItem.length">
              <div
                v-for="(row, index) in vLineItem"
                :key="index"
                class="d-flex my-2"
              >
                <div
                  class="font-weight-600 text-center d-flex justify-center"
                  style="width: 5%"
                >
                  <v-checkbox
                    v-model="row.status"
                    hide-details
                    class="my-0 py-0 px-0 mx-0 dense-checkbox"
                    color="cyan"
                  />
                </div>
                <div class="text-left" style="width: 60%">
                  <div class="d-flex">
                    <v-avatar size="80">
                      <img
                        v-if="row?.image?.url"
                        :src="row?.image?.url"
                        :alt="row?.image?.name"
                      />
                      <img v-else :src="$defaultImage" />
                    </v-avatar>
                    <div style="width: 100%" class="ml-4">
                      <v-text-field
                        v-model="row.product"
                        class="line-item-header-text px-0"
                        :placeholder="productType"
                        dense
                        filled
                        solo
                        flat
                        color="cyan"
                      />
                      <v-textarea
                        class="px-0"
                        v-model="row.description"
                        placeholder="Description"
                        dense
                        filled
                        solo
                        flat
                        rows="2"
                        auto-grow
                        color="cyan"
                      />
                      <!--   <v-checkbox
                           v-if="row.type == 'product'"
                          inset
                          color="cyan"
                          label="As Equipment"
                          dense
                          class="pt-0 mt-2"
                          v-model="row.is_equipment" 
                          hide-details
                          :false-value="0"
                          :true-value="1"
                        > 
                      </v-checkbox>  -->
                      <div
                        v-if="row.has_property"
                        class="font-weight-600 d-flex align-center"
                      >
                        <template v-if="row?.property?.parent?.name">
                          <v-chip>{{ row.property.parent.name }}</v-chip>
                          <v-icon class="px-1">mdi-chevron-right</v-icon>
                        </template>
                        <template v-if="row?.property?.child?.name">
                          <v-chip>{{ row.property.child.name }}</v-chip>
                          <v-icon class="px-1">mdi-chevron-right</v-icon>
                        </template>
                        <template v-if="row?.property?.type == 'child-child-'">
                          <v-chip>{{ row.property.name }}</v-chip>
                        </template>
                        <template v-if="row?.property?.type == 'child-'">
                          <v-chip>{{ row.property.name }}</v-chip>
                        </template>
                        <template v-if="row?.property?.type == 'parent-'">
                          <v-chip>{{ row.property.name }}</v-chip>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center" style="width: 17.5%">
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Selling/Unit Cost"
                    dense
                    filled
                    solo
                    type="number"
                    v-model="row.rate"
                    flat
                    v-on:keypress="limitDecimal($event, row.rate)"
                    color="cyan"
                  />
                </div>
                <div class="text-center" style="width: 17.5%">
                  <v-text-field
                    class="line-item-header-text px-0"
                    placeholder="Quantity"
                    dense
                    filled
                    type="number"
                    v-model="row.quantity"
                    solo
                    flat
                    v-on:keypress="isNumber($event)"
                    color="cyan"
                  />
                </div>
              </div>
            </div>
            <div v-else>
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no item at the moment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { GET_LINE_ITEM, SET_LOADING } from "@/core/services/store/visit.module";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  name: "line-item-dialog-v2",
  props: {
    productType: {
      type: String,
      default: null,
    },
    productDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      search: null,
      category: "all",
      alphabet: "all",
    };
  },
  components: {
    Dialog,
  },
  methods: {
    find_items() {
      let productType = this.productType;
      if (productType == "product") {
        productType = "goods";
      }
      this.$store.dispatch(SET_LOADING, true);
      this.$store
        .dispatch(GET_LINE_ITEM, {
          type: productType,
          search: this.search,
          category: this.category,
          alphabet: this.alphabet,
          customer: this.vCustomerId,
        })
        .then((output) => {
          console.log({ output });
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.$store.dispatch(SET_LOADING, false);
        });
    },
    save_line_item() {
      let selected_line_items = [];
      let status = false;
      for (let item of this.vLineItem) {
        if (item.status) {
          status = true;
          selected_line_items.push(item);
        }
      }
      if (!status) {
        ErrorEventBus.$emit(
          "update:error",
          `Please select ${this.productType}.`
        );
        return false;
      }
      this.$emit("update-values", selected_line_items);
      this.$emit("close", true);
    },
    update_all_line_item(status) {
      for (let i = 0; i < this.vLineItem.length; i++) {
        this.vLineItem[i].status = status;
      }
    },
    filter_cat(param) {
      this.category = param;
      this.find_items();
    },
    filter_alpha(param) {
      this.alphabet = param;
      this.find_items();
    },
  },
  mounted() {
    if (this.productDialog) {
      this.find_items();
    }
  },
  computed: {
    ...mapGetters([
      "vCustomerId",
      "vLineItem",
      "vLineItemAlpha",
      "vStateLoading",
      "vLineItemCategory",
    ]),
    dialog_width() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
};
</script>
