"use strict";

import { sortBy, isEqual } from "lodash";
class ManageVisits {
    scheduleCompare(prevObject, newObject) {
        try {
            return prevObject?.schedule_message == newObject?.message;
        } catch (error) {
            console.log(error, "ManageJob::scheduleCompare::error");
        }
    }

    teamCompare(prevObject, newObject) {
        try {
            const prevTeam = prevObject?.engineer?.map((row) => row.id);
            const newTeam = newObject?.engineer?.map((row) => row.id);
            return isEqual(sortBy(prevTeam), sortBy(newTeam));
        } catch (error) {
            console.log(error, "ManageJob::teamCompare::error");
        }
    }

    visitCompare(prevObject, newObject) {
        try {
            if ((prevObject?.title != newObject?.title) || (prevObject?.admin_reminder != newObject?.admin_reminder) || (prevObject?.customer_reminder != newObject?.customer_reminder) || (prevObject?.technician_reminder != newObject?.technician_reminder)) {
                return false
            }
            return true
        } catch (error) {
            console.log(error, "ManageJob::teamCompare::error");
        }
    }
}
export default ManageVisits;