<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-layout class="px-4">
        <v-flex style="width: 90%">
          <p class="m-0 text-truncate">Select a Contact Person</p>
        </v-flex>
        <v-flex style="width: 10%" class="text-right">
          <v-btn
            style="height: 32px !important"
            class="custom-bold-button"
            depressed
            icon
            v-on:click="$emit('close', true)"
            color="red white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-flex>
      </v-layout>
    </template>
    <template v-slot:body>
      <div class="px-4 w-100">
        <label for="contact-person-search" class="font-weight-600"
          >Which contact person would you like to select this for?</label
        >
        <div class="d-flex mb-2">
          <v-text-field
            id="contact-person-search"
            v-model="search"
            class="line-item-header-text px-0 v-slot-m-none"
            placeholder="Search Contact Person..."
            dense
            filled
            solo
            flat
            color="cyan"
            :loading="loading"
            :disabled="loading"
            style="max-width: 400px"
          />
          <v-btn
            v-on:click="getPersons()"
            :loading="loading"
            :disabled="loading"
            style="height: 32px !important"
            class="custom-bold-button ml-2"
            depressed
            color="cyan white--text"
            >Search</v-btn
          >
        </div>
        <div
          class="custom-border-top"
          style="max-height: calc(100vh - 300px); overflow-y: scroll"
        >
          <div v-if="list.length">
            <div
              v-for="(row, index) in list"
              :key="`contact-person-list-key-${index}`"
              class="cursor-pointer hover-grey d-flex align-center custom-border-bottom py-3 px-3"
              v-on:click="selectPerson(row)"
            >
              <div style="width: 10%" class="text-center">
                <v-icon large>mdi-account</v-icon>
              </div>
              <div style="width: 90%">
                <p class="m-0 font-weight-500 font-size-16">
                  <Barcode :barcode="row.barcode" />
                </p>
                <p class="m-0 font-weight-500 font-size-16">
                  <v-icon class="chip-custom-blue mr-2" small
                    >mdi-account</v-icon
                  >{{ row.display_name }}
                </p>
                <p class="m-0 font-weight-500 font-size-16">
                  <v-icon class="chip-custom-blue mr-2" small>mdi-email</v-icon
                  >{{ row.primary_email }}
                </p>
                <p class="m-0 font-weight-500 font-size-16">
                  <v-icon class="chip-custom-blue mr-2" small
                    >mdi-cellphone</v-icon
                  >{{ row.primary_phone }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center py-3 font-weight-500"
          >
            <img
              width="40px"
              :src="$assetURL('media/error/empty.png')"
              class="row-not-found-image mr-2"
            />
            Uhh... There are no contact person at the moment.
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { QUERY } from "@/core/services/store/request.module";
import {
  SET_PROPERTY_CONTACT,
  SET_BILLING_CONTACT,
} from "@/core/services/store/visit.module";
import ObjectPath from "object-path";

export default {
  name: "select-person",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      search: null,
      list: [],
    };
  },
  watch: {
    dialog(param) {
      if (param) {
        this.getPersons();
      }
    },
  },
  components: {
    Dialog,
    Barcode,
  },
  methods: {
    selectPerson(row) {
      if (this.type == "billing") {
        this.$store.dispatch(SET_BILLING_CONTACT, row);
      }

      if (this.type == "property") {
        this.$store.dispatch(SET_PROPERTY_CONTACT, row);
      }

      this.$emit("close", true);
      this.loading = false;
      this.search = null;
      this.list = [];
    },
    getPersons() {
      this.loading = true;
      this.list = [];
      this.$store
        .dispatch(QUERY, {
          url: `contact-person-list/${this.vCustomerId}`,
          data: { search: this.search },
        })
        .then((response) => {
          this.list = ObjectPath.get(response, "data", []);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["vCustomer", "vCustomerId"]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 40);
    },
  },
};
</script>
