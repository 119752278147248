<template>
    <v-sheet>
        <v-tabs active-class="custom-tab-active" v-model="reminderTab" background-color="transparent" color="cyan"
            class="custom-tab-transparent tab-sticky">
            <v-tab class="font-size-16 font-weight-600 px-8" href="#customer">
                Customer 
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#technician">
                Technician
            </v-tab>
            <v-tab class="font-size-16 font-weight-600 px-8" href="#admin">
                Admin
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="reminderTab">
            <v-tab-item value="customer">
                <v-card-text ref="term-condition" class="p-6 font-size-16">
                  <EmailReminderChild type="customer" :parent-id="parentId" :reminder-obj="this.reminderObj" v-on:update:data="updateDataCustomer($event,'customer')"></EmailReminderChild>
                </v-card-text>
            </v-tab-item>
            <v-tab-item value="technician">
                <v-card-text ref="term-condition" class="p-6 font-size-16">
                  <EmailReminderChild type="staff" :reminder-obj="this.reminderObj" v-on:update:data="updateDataCustomer($event,'staff')"></EmailReminderChild>                   
                </v-card-text>
            </v-tab-item>
            <v-tab-item value="admin">
                <v-card-text ref="term-condition" class="p-6 font-size-16">
                  <EmailReminderChild type="admin" :reminder-obj="this.reminderObj" v-on:update:data="updateDataCustomer($event,'admin')"></EmailReminderChild>
                </v-card-text>
            </v-tab-item>
        </v-tabs-items>
    </v-sheet>
</template>
  
<script>
import EmailReminderChild from "@/view/pages/job/partials/Email-Reminder-singale.vue";
import { SET_REMINDER } from "@/core/services/store/visit.module";
import { toSafeInteger} from "lodash";
import { mapGetters } from "vuex";
export default {
    props: {
        classLabel: {
            type: String,
            default: 'remiderCustom',
        },
        parentId: {
            type: Number,
            default: 0,
        },
        parentType: {
            type: String,
            default: null,
        }
        /*  detail: {
           type: Object,
           default() {
             return new Object();
           },
         },
         visit: {
           type: Object,
           default() {
             return new Object();
           },
         },
         pageLoading: {
           type: Boolean,
           default: false,
         }, */
    },
    watch: {
        parentId(param) {
        if (param) {
            this.getReminderNotifications();
        }
        },
    },
    data() {
        return {
            reminderObj : {}, 
            reminderTab:'customer',
        };
    },
    methods: {
        updateDataCustomer(data,type){
            if(type == 'customer'){
                this.reminderObj.customer = data
            }
            if(type == 'staff'){
                this.reminderObj.staff = data
            }
            if(type == 'admin'){
                this.reminderObj.admin = data
            }

           this.$emit('update:data',this.reminderObj);
        },
        getReminderNotifications() {
            this.$store
                .dispatch(SET_REMINDER, {parent: toSafeInteger(this.parentId), parent_type: this.parentType})
                .then(() => {
                    this.reminderObj = this.vReminder;
                })
                .catch((error) => {
                console.log({ error });
                })
                .finally(() => {
            
                });
        },
     
    },
    mounted(){
    },
    computed: {
        ...mapGetters([
            "vReminder",
        ]),
    },
    components: {
        EmailReminderChild
    },
};
</script>
  