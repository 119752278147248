<template>
  <div
    class="line-item-v2"
    :class="do_update > 0 && ticketDetail?.type == 2 ? 'custompointer' : ''"
  >
    <div class="d-flex mb-2 align-center justify-space-between">
      <div>
        <v-btn-toggle color="cyan" group>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="product"
            v-on:click="open_dialog('product')"
          >
            Product
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="service"
            v-on:click="open_dialog('service')"
          >
            Service
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="equipment"
            v-on:click="open_dialog('equipment')"
          >
            Equipment
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            v-on:click="add_header()"
          >
            Header
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_other()"
          >
            Other
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex align-center">
        <v-checkbox
          class="my-0 py-0"
          v-model="show_price"
          v-on:change="emit_values()"
          color="cyan white--text"
          label="Show Price To Engineer"
        />
        <div
          class="d-flex align-center ml-4 pl-4 custom-border-left"
          v-if="false"
        >
          <div class="mr-2">
            <label for="discount-level" class="mb-0 font-weight-600"
              >Discount Level</label
            >
          </div>
          <div>
            <v-radio-group
              v-on:change="calculate_total()"
              id="discount-level"
              v-model="discount_level"
              row
              hide-details
              class="px-0 py-0"
            >
              <v-radio
                label="Line Item"
                value="line_item"
                color="cyan"
              ></v-radio>
              <v-radio
                label="Transaction"
                value="transaction"
                color="cyan"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-grey-border">
      <div class="d-flex grey lighten-4">
        <div
          class="color-custom-blue py-2 font-weight-700 text-center"
          style="width: 5%"
        >
          #
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 text-left"
          :style="get_col_style('product')"
        >
          Product/Service/Equipment
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Selling/Unit Cost
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Quantity
        </div>
        <div
          v-if="discount_level == 'line_item'"
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Discount
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700 px-2"
          style="width: 10%"
        >
          Total
        </div>
        <div
          class="color-custom-blue py-2 font-weight-700"
          style="width: 5%"
        ></div>
      </div>
      <Draggable
        tag="ul"
        v-model="selected_line_items"
        class="line-item-drag-group"
        draggable=".line-item-drag"
        handle=".line-item-drag-icon"
        ghost-class="line-item-ghost"
        animation="200"
        group="line-item-drag"
        v-on:start="drag = true"
        v-on:end="drag = false"
        v-on:change="emit_values()"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <li
            v-for="(row, index) in selected_line_items"
            class="line-item-drag"
            :class="{
              'alternate-line-item-row':
                selected_line_items[index + 1]?.is_child,
            }"
            :key="`line-item-drag-${index}`"
          >
            <div
              :id="`line-item-drag-${index}`"
              class="d-flex my-2"
              :class="{ 'item-is-child': row.is_child }"
            >
              <div class="font-weight-600 text-center" style="width: 5%">
                <v-icon
                  v-if="!row.is_child"
                  class="line-item-drag-icon cursor-move"
                  color="color-custom-blue"
                  >mdi-drag</v-icon
                >
              </div>
              <div
                v-if="row.is_child"
                class="font-weight-600 text-center"
                style="width: 5%"
              >
                <v-icon
                  class="line-item-drag-icon cursor-move"
                  color="color-custom-blue"
                  >mdi-drag</v-icon
                >
              </div>
              <div
                v-if="row.type == 'header'"
                class="text-left px-2"
                style="width: 90%"
              >
                <v-text-field
                  v-model="row.product"
                  class="line-item-header-text px-0"
                  placeholder="Header"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="changeName(index, row.product)"
                  color="cyan"
                >
                  <template v-slot:append>
                    <v-chip
                      color="cyan white--text"
                      label
                      class="lip-type text-uppercase font-weight-600 header"
                    >
                      Header
                    </v-chip>
                  </template>
                </v-text-field>
              </div>
              <div
                v-else
                class="text-left px-2"
                :style="get_col_style('product', row.is_child)"
              >
                <div class="d-flex">
                  <v-avatar size="55">
                    <img
                      v-if="row?.image?.url"
                      :src="row?.image?.url"
                      :alt="row?.image?.name"
                    />
                    <img v-else :src="$defaultImage" />
                  </v-avatar>
                  <div style="width: 100%" class="ml-4">
                    <v-expansion-panels class="cepfli">
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <v-text-field
                            v-model="row.product"
                            class="line-item-header-text px-0"
                            placeholder="Product/Service/Equipment"
                            dense
                            filled
                            solo
                            v-on:change="changeName(index, row.product)"
                            flat
                            color="cyan"
                          >
                            <template v-slot:append>
                              <v-chip
                                color="cyan white--text"
                                label
                                class="lip-type text-uppercase font-weight-600"
                                :class="{
                                  product: row.product_type == 'goods',
                                  service: row.product_type == 'service',
                                  equipment: row.product_type == 'equipment',
                                  other: row.product_type == 'other',
                                }"
                              >
                                {{ row.type }}
                              </v-chip>
                            </template>
                          </v-text-field>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-textarea
                            class="px-0"
                            v-model="row.description"
                            placeholder="Description"
                            dense
                            filled
                            solo
                            flat
                            rows="2"
                            auto-grow
                            color="cyan"
                            v-on:change="changeDes(index, row.description)"
                          />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <div class="d-flex">
                      `
                      <v-checkbox
                        v-if="row.type == 'product'"
                        inset
                        color="cyan"
                        label="As Equipment"
                        dense
                        class="pt-0 mt-2"
                        v-model="row.is_equipment"
                        hide-details
                        :false-value="0"
                        :true-value="1"
                        v-on:change="changeEquipment(index, row.is_equipment)"
                      >
                      </v-checkbox>
                      <!--   <template v-if="row.equipment_warranty != 1">
                        <template  v-if="row.has_warranty == 1 && row.type == 'product' && row.is_equipment == 1">
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                class="mx-2 custom-bold-button white--text mt-2"
                                color="cyan"
                                tile
                                style="height: 20px !important;"
                                v-on:click="OpenchangeWarrnty(index,row)"
                              >
                                Warranty
                              </v-btn>
                            </template>
                            <span>Equipment Warranty</span>
                          </v-tooltip>`
                        </template>
                      </template>
                      <template  v-if="row.has_warranty == 1 && row.type == 'product' && row.is_equipment == 1 && row.equipment_warranty == 1">
                           <v-chip label small text-color="white" color="red" class="mx-2 mt-2"> Warranty Created </v-chip>
                      </template>
                       <em v-if="row.has_warranty == 0 && row.type == 'product' && row.is_equipment == 1 " class="mx-2 mt-2 font-weight-600"> (no product warranty) </em> -->
                    </div>

                    <div
                      v-if="row.has_property"
                      class="font-weight-600 d-flex align-center"
                    >
                      <template v-if="row?.property?.parent?.name">
                        <v-chip>{{ row.property.parent.name }}</v-chip>
                        <v-icon class="px-1">mdi-chevron-right</v-icon>
                      </template>
                      <template v-if="row?.property?.child?.name">
                        <v-chip>{{ row.property.child.name }}</v-chip>
                        <v-icon class="px-1">mdi-chevron-right</v-icon>
                      </template>
                      <template v-if="row?.property?.type == 'child-child-'">
                        <v-chip>{{ row.property.name }}</v-chip>
                      </template>
                      <template v-if="row?.property?.type == 'child-'">
                        <v-chip>{{ row.property.name }}</v-chip>
                      </template>
                      <template v-if="row?.property?.type == 'parent-'">
                        <v-chip>{{ row.property.name }}</v-chip>
                      </template>
                      <v-btn
                        v-if="false && row?.property?.id"
                        v-on:click="remove_property(index)"
                        icon
                        rounded
                        color="red lighten-1"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!(row.type == 'header')"
                class="text-center px-2"
                style="width: 10%"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Selling/Unit Cost"
                  dense
                  filled
                  solo
                  flat
                  type="number"
                  v-model="row.rate"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, row.rate)"
                  color="cyan"
                />
              </div>
              <div
                v-if="!(row.type == 'header')"
                class="text-center px-2"
                style="width: 10%"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Quantity"
                  dense
                  filled
                  solo
                  flat
                  type="number"
                  v-model="row.quantity"
                  :prepend="row.uom"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="isNumber($event)"
                  color="cyan"
                />
              </div>
              <div
                v-if="discount_level == 'line_item' && !(row.type == 'header')"
                class="text-center px-2"
                style="width: 10%"
              >
                <div class="d-flex">
                  <v-text-field
                    hide-details
                    class="line-item-header-text px-0"
                    placeholder="Discount"
                    dense
                    filled
                    solo
                    flat
                    type="number"
                    v-model="row.discount_value"
                    v-on:keyup="calculate_total()"
                    v-on:keypress="limitDecimal($event, row.discount_value)"
                    color="cyan"
                    style="width: 50%; min-width: unset"
                  />
                  <v-select
                    :items="discount_type_list"
                    v-model="row.discount_type"
                    hide-details
                    item-text="type"
                    item-value="value"
                    item-color="cyan"
                    class="line-item-header-text discount-type text-h6 px-0"
                    placeholder="Type"
                    dense
                    filled
                    solo
                    flat
                    v-on:change="calculate_total()"
                    color="cyan"
                  />
                </div>
              </div>
              <div
                v-if="!(row.type == 'header')"
                class="text-center px-2"
                style="width: 10%"
              >
                <v-text-field
                  class="line-item-header-text px-0"
                  placeholder="Total"
                  dense
                  filled
                  solo
                  flat
                  type="number"
                  v-model="row.total"
                  v-on:keyup="calculate_total()"
                  v-on:keypress="limitDecimal($event, row.total)"
                  color="cyan"
                />
              </div>
              <div class="font-weight-600 text-right" style="width: 80px">
                <template v-if="!row.is_child">
                  <v-menu v-if="row.has_child" bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="blue darken-4"
                      >
                        <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <v-list class="py-0" min-width="150px">
                      <!--  <v-list-item
                        v-if="row.type == 'equipment'"
                        link
                        class="border-bottom"
                        v-on:click="add_child(index, 'property')"
                      >
                        <v-list-item-title
                          v-if="row?.property?.id"
                          class="font-weight-600"
                        >
                          Manage Site Location</v-list-item-title
                        >
                        <v-list-item-title v-else class="font-weight-600">
                          Add Site Location</v-list-item-title
                        >
                      </v-list-item> -->
                      <v-list-item
                        v-if="!row.is_child"
                        link
                        class="border-bottom"
                        v-on:click="add_child(index, 'equipment')"
                      >
                        <v-list-item-title class="font-weight-600">
                          Add Equipment</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="!row.is_child"
                        link
                        class="border-bottom"
                        v-on:click="add_child(index, 'product')"
                      >
                        <v-list-item-title class="font-weight-600">
                          Add Product</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item
                        v-if="!row.is_child"
                        link
                        v-on:click="add_child(index, 'service')"
                      >
                        <v-list-item-title class="font-weight-600">
                          Add Service</v-list-item-title
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  v-on:click="remove_row(index)"
                  icon
                  color="red lighten-1"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </li>
        </transition-group>
      </Draggable>
      <template v-if="!selected_line_items.length">
        <div class="custom-border-top p-3">
          <p
            class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border d-flex align-center"
          >
            <v-icon class="mr-1">mdi-alert-circle-outline</v-icon>
            Oops... Nothing Found.
          </p>
        </div>
      </template>
    </div>
    <div class="d-flex mt-2">
      <!-- <v-btn-toggle v-model="product_type" color="cyan" group>
        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="product"
          v-on:click="open_dialog('product')"
        >
          Product
        </v-btn>
        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="service"
          v-on:click="open_dialog('service')"
        >
          Service
        </v-btn>
        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="equipment"
          v-on:click="open_dialog('equipment')"
        >
          Equipment
        </v-btn>
        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="header"
          v-on:click="add_header()"
        >
          Header
        </v-btn>
        <v-btn
          color="cyan"
          class="custom-bold-button"
          value="other"
          v-on:click="add_other()"
        >
          Other
        </v-btn>
      </v-btn-toggle> -->
      <div>
        <v-btn-toggle color="cyan" group>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="product"
            v-on:click="open_dialog('product')"
          >
            Product
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="service"
            v-on:click="open_dialog('service')"
          >
            Service
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="equipment"
            v-on:click="open_dialog('equipment')"
          >
            Equipment
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            v-on:click="add_header()"
          >
            Header
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_other()"
          >
            Other
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div>
      <table width="100%" style="table-layout: fixed">
        <tbody>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Sub Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(subtotal) }}
            </td>
          </tr>
          <tr v-if="discount_level == 'transaction'">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Discount
            </td>
            <td width="15%" valign="middle" align="center">
              <div class="d-flex">
                <v-text-field
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Discount"
                  dense
                  filled
                  solo
                  flat
                  v-model="discount_value"
                  v-on:keyup="calculate_total(), checkData()"
                  v-on:keypress="
                    limitDecimal($event, discount_value), checkData()
                  "
                  v-on:change="checkData()"
                  color="cyan"
                />
                <v-select
                  :items="discount_type_list"
                  v-model="discount_type"
                  hide-details
                  item-text="type"
                  item-value="value"
                  item-color="cyan"
                  class="line-item-header-text discount-type text-h6 px-0"
                  placeholder="Type"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="calculate_total()"
                  color="cyan"
                />
              </div>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(discount) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Tax [GST {{ gst_value }}%]
            </td>
            <td width="15%" valign="middle" align="center">
              <v-checkbox
                v-on:change="calculate_total()"
                v-model="tax_active"
                class="my-0 py-0 px-0 mx-0"
                color="cyan"
                :label="`Tax Amount: ${formatMoney(gst_amount)}`"
              />
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(gst) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Adjustment
            </td>
            <td width="15%" valign="middle" align="center">
              <v-text-field
                hide-details
                class="line-item-header-text px-0 line-item-adjustment"
                placeholder="Adjustment"
                dense
                filled
                solo
                flat
                v-model="adjustment"
                v-on:keyup="calculate_total()"
                v-on:keypress="limitDecimal($event, adjustment)"
                color="cyan"
              >
                <template v-slot:append>
                  <v-btn depressed v-on:click="auto_adjust()">Auto</v-btn>
                </template>
              </v-text-field>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(adjustment) }}
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Grand Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(total) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <LineItemDialog
      :key="`line-item-dialog-${dialog_key}`"
      :product-type="product_type"
      :product-dialog="product_dialog"
      v-on:update-values="update_line_item($event)"
      v-on:close="product_dialog = false"
    />
    <ManageWarranty
      :dialog-status="addWarrantyDialog"
      v-on:close:dialog="addWarrantyDialog = false"
      v-on:warrnty:data="warrantyData($event)"
    ></ManageWarranty>
    <AddressSelect
      line-item
      :key="`address-select-dialog-${dialog_key}`"
      :dialog="property_dialog"
      v-on:close="property_dialog = false"
      type="property"
      label="Service Location"
      v-on:update="update_child_property($event)"
    />
  </div>
</template>

<script>
import { GET } from "@/core/services/store/request.module";
import { mapGetters } from "vuex";
import Draggable from "vuedraggable";
import AddressSelect from "@/view/components/LineItemAddressSelect.vue";
import LineItemDialog from "@/view/components/LineItemDialog.vue";
import AppConfiguration from "@/core/config/app.config.js";

import {
  SET_VISIT_LINE_ITEM,
  SET_VISIT_CALCULATION,
} from "@/core/services/store/visit.module";
import { toBtxNumber, toBtxFixed } from "@/core/services/common.service";
import CommonMixin from "@/core/plugins/common-mixin.js";
import { find } from "lodash";
import { ErrorEventBus } from "@/core/lib/message.lib";
import { v4 as uuidv4 } from "uuid";
import ManageWarranty from "@/view/pages/partials/Create-Warranty-Intenal.vue";
export default {
  name: "line-item-v1",
  mixins: [CommonMixin],
  data() {
    return {
      drag: false,
      dialog_key: Number(new Date()),
      product_type: null,
      product_dialog: false,
      child_index: null,
      addWarrantyDialog: false,
      append_child: false,
      warrantyObject: {},
      property_dialog: false,
      tax_active: false,
      do_update: 0,
      show_price: false,
      discount_level: "transaction",
      selected_line_items: [],
      subtotal: 0,
      discount: 0,
      gst: 0,
      gst_amount: 0,
      gst_value: 9,
      adjustment: 0,
      total: 0,
      discount_value: null,
      discount_type: 1,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
    };
  },
  props: {
    ticketDetail: {
      type: Object,
      default: null,
    },
    dbLineItems: {
      type: Array,
      default() {
        return [];
      },
    },
    dbEquipments: {
      type: Array,
      default() {
        return [];
      },
    },
    entityId: {
      type: Number,
      default: 0,
    },
    isJob: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    ticketDetail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param && param.id && param.id > 0) {
          this.get_line_items(param);
        }
      },
    },
    /*  warrantyObject: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param, "paramparamparam");
    
      },
    }, */
  },
  methods: {
    auto_adjust() {
      let total = toBtxNumber(this.total);
      if (total > 0) {
        let adjustment = toBtxFixed(total / 10) * 10 - total;
        this.adjustment = toBtxFixed(adjustment);
        this.$nextTick(() => {
          this.calculate_total();
        });
      }
    },
    get_col_style(param, child) {
      let width = 46;
      if (param == "product") {
        if (!this.show_price) {
          width = 70;
        } else if (this.show_price) {
          width = 46;
          if (this.discount_level == "transaction") {
            width = 70;
          }
        }
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    },
    calculate_total() {
      let d_type = this.discount_level;

      this.subtotal = 0;
      this.discount = 0;
      this.gst = 0;
      this.gst_amount = 0;

      for (let i = 0; i < this.selected_line_items.length; i++) {
        let total = this.accountingToFixed(
          toBtxNumber(this.selected_line_items[i].quantity) *
            toBtxNumber(this.selected_line_items[i].rate)
        );
        let discount = 0;

        if (d_type == "line_item") {
          if (this.selected_line_items[i].discount_type == 1) {
            discount = toBtxNumber(this.selected_line_items[i].discount_value);
          }
          if (this.selected_line_items[i].discount_type == 2) {
            discount = this.accountingToFixed(
              (toBtxNumber(total) *
                toBtxNumber(this.selected_line_items[i].discount_value)) /
                100
            );
          }
        }
        this.selected_line_items[i].total =
          isNaN(total) || !total
            ? 0
            : toBtxNumber(total) - toBtxNumber(discount);
        this.subtotal = this.accountingToFixed(
          toBtxNumber(this.subtotal) +
            toBtxNumber(this.selected_line_items[i].total)
        );
      }

      let subtotal = this.subtotal;

      if (d_type == "transaction") {
        this.discount = 0;
        if (this.discount_type == 1) {
          this.discount = toBtxNumber(this.discount_value);
        }
        if (this.discount_type == 2) {
          this.discount = this.accountingToFixed(
            (toBtxNumber(subtotal) * toBtxNumber(this.discount_value)) / 100
          );
        }
      }

      subtotal = toBtxNumber(subtotal) - toBtxNumber(this.discount);

      if (this.tax_active) {
        this.gst_amount = subtotal;
        this.gst = this.accountingToFixed(
          (toBtxNumber(subtotal) * toBtxNumber(this.gst_value)) / 100
        );
      }

      subtotal = toBtxNumber(subtotal) + toBtxNumber(this.gst);

      this.total = this.accountingToFixed(
        toBtxNumber(subtotal) + toBtxNumber(this.adjustment)
      );
      this.emit_values();
    },
    update_line_item(param) {
      if (this.append_child) {
        let child_index = toBtxNumber(this.child_index);
        for (let item of param) {
          item.is_child = true;
          child_index = child_index + 1;
          this.selected_line_items = this.arrayInsert(
            this.selected_line_items,
            child_index,
            item
          );
        }
      } else {
        for (let item of param) {
          this.selected_line_items.push(item);
        }
      }

      this.calculate_total();

      this.$nextTick(() => {
        this.emit_values();
        this.product_dialog = false;
        this.append_child = false;
        this.product_type = null;
        this.child_index = null;
      });
    },
    remove_property(index) {
      this.selected_line_items[index].property = new Object();

      this.$nextTick(() => {
        this.emit_values();
      });
    },
    checkData() {
      if (this.discount_type == 2 && this.discount_value) {
        if (this.discount_value > 100) {
          this.discount_value = 100;
        }
      }
    },
    changeEquipment(index, row) {
      this.selected_line_items[index].is_equipment = row;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    changeDes(index, row) {
      this.selected_line_items[index].description = row;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    changeName(index, row) {
      this.selected_line_items[index].product = row;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    OpenchangeWarrnty(index) {
      this.eIndex = index;
      this.addWarrantyDialog = true;
    },
    warrantyData(data) {
      this.selected_line_items[this.eIndex].equipment_warranty = 0;
      this.selected_line_items[this.eIndex].warranty_cost = data.cost;
      this.selected_line_items[this.eIndex].warranty_description =
        data.description;
      this.selected_line_items[this.eIndex].warranty_duration = data.duration;
      this.selected_line_items[this.eIndex].warranty_duration_type =
        data.duration_type;
      this.selected_line_items[this.eIndex].warranty_end_date = data.end_date;
      this.selected_line_items[this.eIndex].warranty_start_date =
        data.start_date;
      this.selected_line_items[this.eIndex].warranty_unique_id = data.unique_id;
      this.selected_line_items[this.eIndex].warranty_type = data.warranty_type;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    changeWarrnty(index, row) {
      this.selected_line_items[index].equipment_warranty =
        row.equipment_warranty;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    update_child_property(row) {
      this.selected_line_items[this.child_index].property = row;
      this.$nextTick(() => {
        this.emit_values();
      });
    },
    add_child(index, type) {
      this.append_child = true;
      this.dialog_key = Number(new Date());
      this.child_index = index;
      if (type == "property" || type == "equipment") {
        if (!this.vCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
        if (type == "equipment") {
          this.product_type = type;
          this.product_dialog = true;
        } else {
          this.property_dialog = true;
        }
      }
      if (type == "product") {
        this.product_type = type;
        this.product_dialog = true;
      }

      if (type == "service") {
        this.product_type = type;
        this.product_dialog = true;
      }
    },
    emit_values() {
      this.$nextTick(() => {
        this.$store.dispatch(SET_VISIT_CALCULATION, {
          discount_type: this.discount_type,
          discount_value: this.discount_value,
          tax_active: this.tax_active,
          adjustment: this.adjustment,
          show_price: this.show_price,
          discount_level: this.discount_level,
          tax_value: this.gst_value,
        });
        this.$store.dispatch(SET_VISIT_LINE_ITEM, this.selected_line_items);
      });
    },
    open_dialog(param) {
      if (param == "equipment") {
        if (!this.vCustomerId) {
          this.scrollToTop();
          ErrorEventBus.$emit("update:error", "Please Select Customer.");
          return false;
        }
      }
      this.dialog_key = Number(new Date());
      this.product_type = param;
      this.product_dialog = true;
    },
    add_header() {
      let len = this.selected_line_items.length + 1;
      this.selected_line_items.push({
        status: true,
        index: uuidv4(),
        uuid: uuidv4(),
        id: 0,
        order: len,
        type: "header",
        product_type: "header",
        is_child: false,
        has_child: false,
        has_property: false,
        product: null,
      });
      console.log(this.selected_line_items, "index");
      this.$nextTick(() => {
        this.product_type = null;
        this.emit_values();
      });
    },
    add_other() {
      let len = this.selected_line_items.length + 1;
      this.selected_line_items.push({
        status: true,
        index: uuidv4(),
        uuid: uuidv4(),
        id: null,
        image: {},
        order: len,
        serial_no: null,
        eq_model: null,
        location: null,
        group: null,
        discount: 0,
        type: "other",
        is_child: false,
        parent_uuid: null,
        has_child: true,
        has_property: true,
        property: {},
        group_primary: false,
        discount_value: 0,
        discount_type: 1,
        to_equipment: 0,
        product: null,
        project_price: 0,
        threshold_price: 0,
        product_id: 0,
        product_type: "other",
        description: null,
        has_warranty: false,
        warranty: {},
        rate: 0,
        selling_cost: 0,
        quantity: 1,
        uom: null,
        total: 0,
      });
      this.$nextTick(() => {
        this.product_type = null;
        this.emit_values();
      });
    },
    get_line_items(param) {
      console.log({ param });
      let id = param.id;
      this.loading = true;
      this.$store
        .dispatch(GET, { url: `visit-v1/${id}/line-items` })
        .then(({ data }) => {
          this.selected_line_items = data;
          this.selected_line_items.map((row, index) => {
            this.selected_line_items[index].discount_type = row.discount_type;
          });
          (this.show_price = param.show_price == 1 ? true : false),
            (this.adjustment = param.adjustment);
          this.gst_value = param.tax_value;
          this.tax_active = param.tax_applied == 1 ? true : false;
          this.discount_level =
            param.discount_type == 1 ? "transaction" : "line_item";
          if (param && param.discount_value_type) {
            this.discount_value = param.discount_value;
            this.discount_type = param.discount_value_type;
          }
          this.calculate_total();
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    remove_row(index) {
      this.selected_line_items.splice(index, 1);
      this.calculate_total();
      this.emit_values();
    },
    isGSTAllowed() {
      const app_config = AppConfiguration.get();
      const { entity } = app_config;
      const currentEntity = find(entity, { id: this.entityId });

      this.gst_value = currentEntity.gstvalue > 0 ? currentEntity.gstvalue : 9;
      if (currentEntity) {
        if (currentEntity.gst) {
          if (this.isJob) {
            this.tax_active = true;
            return true;
          } else {
            this.tax_active = false;
          }
        }
      }
      return true;
    },
  },
  components: {
    Draggable,
    LineItemDialog,
    AddressSelect,
    ManageWarranty,
  },
  mounted() {
    this.do_update = this.lodash.toSafeInteger(this.$route.query.do_update);
    this.isGSTAllowed();
  },
  computed: {
    ...mapGetters([
      "vCustomerId",
      "vStateLoading",
      "vLineItem",
      "vSelectedLineItem",
      "vLineItemAlpha",
      "vLineItemCategory",
    ]),
  },
};
</script>
